import { Text, View } from '@react-pdf/renderer';
import classNames from 'classnames';
import { useEffect, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { getUnreadMessagesCount, registerUnreadCounter } from 'app/unreadCount';
import List, { ListToPdf, listToDocx } from 'components/lists/List';
import { FileChild, ISectionOptions, SectionType } from 'docx';
import { IBlock } from 'features/block/blockAPI';
import { BlockType, ListType } from 'features/block/blockSlice';
import { StateMan } from 'features/dialogue/dialogueSlice';
import { IList, LIST_SORTBY } from 'features/list/listAPI';
import { IListItem } from 'features/list/listItemAPI';
import {
  asyncApiListNew,
  asyncApiListRemoved,
  asyncApiListUpdated,
} from 'features/list/listSlice';
import { getVisitedState } from 'features/uiState/uiStateSlice';
import { Retrieving, constructURL } from 'helpers/apiTypes';
import { ExportProps } from 'helpers/export';
import { AnyOrderObject } from 'helpers/objects';
import { sortByOrder } from 'helpers/sorting';
import { ChildBlockProps } from './Block';

import { SettingSelect } from 'components/forms/SettingSelect';
import pfdStyles from '../../css/pdfStyles';
import './ChatBlock.scss';
import './ListBlock.scss';

function getUnread(block: IBlock): number {
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];
  const allMsgs = (block.childListBlock?.lists ?? [])
    .reduce((accumulator: IListItem[], currentList: IList) => {
      return accumulator.concat(currentList.listItems ?? []);
    }, [])
    .map((item) => item.message ?? {});
  let count = getUnreadMessagesCount(allMsgs, lastVisited);
  allMsgs.forEach((msg) => {
    if (msg.replies) count += getUnreadMessagesCount(msg.replies, lastVisited);
  });
  return count;
}
registerUnreadCounter(BlockType.List, getUnread);

type ListBlockContentProps = {
  block: IBlock;
  listType: ListType;
  lists: IList[];
  showLikes: boolean;
  showColors: boolean;
  showAttribution: boolean;
  showDescription?: boolean;
  lastVisited: Date | null;
  stateMan: StateMan;
};

function ListBlockContent(props: ListBlockContentProps) {
  const {
    block,
    listType,
    lists,
    showLikes,
    showColors,
    showAttribution,
    lastVisited,
  } = props;

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o;
  }

  const sortedLists = lists ? sortByOrder(lists as AnyOrderObject[]) : [];
  return (
    <>
      {props.showDescription && block.description ? (
        <div className="block_description">{block.description}</div>
      ) : null}
      <div
        className={classNames('lists', {
          [listType]: true,
        })}
      >
        {sortedLists && sortedLists.length ? (
          sortedLists.map((list: any, i) => (
            <List
              key={list.id}
              list={list}
              index={i}
              stateMan={stateMan}
              block={block}
              showLikes={showLikes}
              showColors={showColors}
              showAttribution={showAttribution}
              lastVisited={lastVisited}
            />
          ))
        ) : (
          <div className={'no_messages'}>
            <FormattedMessage id="DIALOGUE.VIEW.NO_CONTENT" />
          </div>
        )}
      </div>
    </>
  );
}

export function ListBlockToPdf(
  props: {
    block: IBlock;
  } & ExportProps,
) {
  const { dialogue, block, intl } = props;
  const lists = (block.childListBlock?.lists as IList[]) ?? [];

  const showLikes = block.childListBlock?.likes ?? false;
  const showColours = block.childListBlock?.colors ?? false;
  const showAttribution = block.childListBlock?.attribution ?? false;

  const sortedLists = lists ? sortByOrder(lists as AnyOrderObject[]) : [];
  const listStyles: { [key: string]: any } = {
    0: {},
    1: pfdStyles.lists_1,
    2: pfdStyles.lists_2,
    3: pfdStyles.lists_3,
    4: pfdStyles.lists_4,
  };
  return (
    <>
      {sortedLists && sortedLists.length ? (
        <View style={pfdStyles.lists}>
          {sortedLists.map((list: any, i) => (
            <ListToPdf
              style={listStyles[sortedLists.length.toString()]}
              key={list.id}
              list={list}
              index={i}
              block={block}
              showLikes={showLikes}
              showColours={showColours}
              showAttribution={showAttribution}
              intl={intl}
              dialogue={dialogue}
            />
          ))}
        </View>
      ) : (
        <View>
          <Text>{intl.formatMessage({ id: 'DIALOGUE.VIEW.NO_CONTENT' })}</Text>
        </View>
      )}
    </>
  );
}

export async function listBlockToDocx(
  props: {
    block: IBlock;
  } & ExportProps,
): Promise<ISectionOptions> {
  const promise = new Promise<ISectionOptions>(async (resolve, reject) => {
    const { dialogue, block, intl } = props;
    const lists = (block.childListBlock?.lists as IList[]) ?? [];
    const showLikes = block.childListBlock?.likes ?? false;
    const showColours = block.childListBlock?.colors ?? false;
    const showAttribution = block.childListBlock?.attribution ?? false;

    const sortedLists = lists ? sortByOrder(lists as AnyOrderObject[]) : [];
    const listStyles: { [key: string]: any } = {
      0: '',
      1: 'lists_1',
      2: 'lists_2',
      3: 'lists_3',
      4: 'lists_4',
    };
    const listObjects: FileChild[] = [];
    for (const [i, l] of sortedLists.entries()) {
      const listObj = await listToDocx({
        block: block,
        list: l,
        index: i,
        showLikes,
        showColours,
        showAttribution,
        style: listStyles[sortedLists.length.toString()],
        intl,
        dialogue,
      });
      if (listObj) listObjects.push(...listObj);
    }

    resolve({
      properties: { type: SectionType.CONTINUOUS },
      children: listObjects,
    });
  });
  return promise;
}

export default function ListBlock(props: ChildBlockProps) {
  const { block, onUpdate, showDescription, registerDropMenu } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];
  const [sortOptions, _] = useState<Map<string, string>>(new Map());

  const [webSocket] = useState(AsyncAPI.connection);

  useEffect(() => {
    if (block.childListBlock == null) return;

    const asyncAPIQuery = `ListBlock/${
      block.childListBlock?.id
    }?and=${constructURL({
      lists: {
        '*': true,
        listItems: {
          '*': true,
          message: {
            '*': true,
            author: {
              // '*': true,
              about: true,
              email: true,
              id: true,
              isVerified: true,
              locale: true,
              username: true,
            },
            likes: {
              '*': true,
              liker: {
                id: true,
                username: true,
              },
            },
            files: {
              '*': true,
            },
            replies: {
              '*': true,
              author: {
                // '*': true,
                about: true,
                email: true,
                id: true,
                isVerified: true,
                locale: true,
                username: true,
                avatar: {
                  uri: true,
                },
              },
              replyTo: {
                id: true,
              },
            },
            listItem: {
              id: true,
              alt: true,
            },
          },
        },
      },
    } as const satisfies Retrieving<'ListBlock'>)}`;

    console.log(
      `Main useEffect called! (ListBlock) - id: ${block.childListBlock.id} `,
    );

    if (webSocket?.readyState !== 1) {
      console.log(
        'useEffect() main (ListBlock) --> connection is not ready, readyState is not on 1',
      );
      return;
    }
    AsyncAPI.doMessage(asyncAPIQuery);

    const onMessageCallback = (payload: any, change: string) => {
      switch (change) {
        case 'new':
          dispatch(asyncApiListNew({ data: payload, stateMan }));
          break;
        case 'updated':
          dispatch(asyncApiListUpdated({ data: payload, stateMan }));
          break;
        case 'removed':
          dispatch(asyncApiListRemoved({ data: payload, stateMan }));
          break;
      }
      onUpdate();
    };

    AsyncAPI.addOnMessageCallbackQueries(asyncAPIQuery, onMessageCallback);

    return () => {
      if (webSocket?.readyState !== 1) {
        console.log(
          'useEffect() return (ListBlock+List+ListItems) --> connection is not ready, readyState is not on 1',
        );
        return;
      }
      AsyncAPI.doMessageDisconnect(asyncAPIQuery);

      const index = AsyncAPI.onMessageCallbacksQueries.findIndex(
        ({ callback }) => callback === onMessageCallback,
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksQueries.splice(index, 1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, webSocket?.readyState, block.childListBlock?.id]);

  const lists = (block.childListBlock?.lists as IList[]) ?? [];

  useEffect(() => {
    sortOptions.set(
      LIST_SORTBY.ORDER,
      intl.formatMessage({ id: 'LISTS.SORTBY_ORDER' }),
    );
    sortOptions.set(
      LIST_SORTBY.LIKES,
      intl.formatMessage({ id: 'LISTS.SORTBY_LIKES' }),
    );
    sortOptions.set(
      LIST_SORTBY.NEWEST,
      intl.formatMessage({ id: 'LISTS.SORTBY_NEWEST' }),
    );
    sortOptions.set(
      LIST_SORTBY.OLDEST,
      intl.formatMessage({ id: 'LISTS.SORTBY_OLDEST' }),
    );
  }, []);

  useLayoutEffect(() => {
    if (registerDropMenu)
      registerDropMenu((mod: boolean) => {
        return mod ? (
          <SettingSelect
            block={block}
            stateMan={props.stateMan}
            prop={'sortBy'}
            notSub={false}
            label={intl.formatMessage({ id: 'LISTS.SORTBY' })}
            placeholder={intl.formatMessage({ id: 'LISTS.SORTBY_PLCH' })}
            options={sortOptions}
          />
        ) : null;
      });
  }, [block]);

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.childListBlock;
  }

  return (
    <div className="block_content">
      <ListBlockContent
        block={block}
        listType={block?.childListBlock?.listType as ListType}
        showLikes={block?.childListBlock?.likes || false}
        showColors={block?.childListBlock?.colors || false}
        showAttribution={block?.childListBlock?.attribution || false}
        stateMan={stateMan}
        lists={lists}
        showDescription={showDescription}
        lastVisited={lastVisited}
      />
    </div>
  );
}
