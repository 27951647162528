import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import { useAppDispatch } from 'app/hooks';
import { IBlock } from 'features/block/blockAPI';
import { BlockType, patchBlockAsync } from 'features/block/blockSlice';
import { IChatBlock } from 'features/block/chatBlockAPI';
import { IDocumentBlock } from 'features/block/documentBlockAPI';
import { IListBlock } from 'features/block/listBlockAPI';
import { patchListBlockAsync } from 'features/block/listBlockSlice';
import { IPollBlock } from 'features/block/pollBlockAPI';
import { ISudokuBlock } from 'features/block/sudokuBlockAPI';
import { patchSudokuBlockAsync } from 'features/block/sudokuBlockSlice';
import { StateMan } from 'features/dialogue/dialogueSlice';

type SettingCheckboxProps = {
  block: IBlock;
  prop: string;
  label: string;
  notSub?: boolean; // true if the prop is not for a sub-block
  stateMan: StateMan;
};

export const SettingCheckbox = (props: SettingCheckboxProps) => {
  const dispatch = useAppDispatch();
  const { block, stateMan, prop, label, notSub } = props;
  const [checked, setChecked] = useState<boolean | undefined>(false);
  const subBlock = useRef<
    | IChatBlock
    | IListBlock
    | IDocumentBlock
    | IPollBlock
    | ISudokuBlock
    | null
    | undefined
  >();

  useEffect(() => {
    subBlock.current =
      block.childChatBlock ||
      block.childListBlock ||
      block.childDocumentBlock ||
      block.childPollBlock ||
      block.childSudokuBlock;
    setChecked(
      notSub
        ? !!block[prop as keyof typeof block]
        : subBlock.current
          ? !!(subBlock.current as any)[prop as keyof typeof subBlock]
          : undefined,
    );
  }, [block, prop]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setChecked(event.target.checked);
      if (notSub) {
        dispatch(
          patchBlockAsync({
            data: { id: block.id, [prop]: event.target.checked },
            stateMan: stateMan,
          }),
        ).then((response: any) => {
          switch (response.payload.response?.status) {
            case 200:
            case 201:
              break;
            default:
              console.log('Internal error, setting not changed.');
          }
        });
      } else {
        switch (block.childType) {
          case BlockType.List:
            dispatch(
              patchListBlockAsync({
                id: subBlock.current?.id!,
                data: { [prop]: event.target.checked },
                stateMan: stateMan,
              }),
            ).then((response: any) => {
              switch (response.payload.response?.status) {
                case 200:
                case 201:
                  break;
                default:
                  console.log('Internal error, setting not changed.');
              }
            });
            break;
          case BlockType.Sudoku:
            dispatch(
              patchSudokuBlockAsync({
                id: subBlock.current?.id!,
                data: { [prop]: event.target.checked },
                stateMan: stateMan,
              }),
            ).then((response: any) => {
              switch (response.payload.response?.status) {
                case 200:
                case 201:
                  break;
                default:
                  console.log('Internal error, setting not changed.');
              }
            });
            break;
        }
      }
    },
    [block, subBlock, dispatch, stateMan, prop],
  );

  return (
    <Form.Check
      type={'checkbox'}
      id={`${prop}-checkbox-${block.id}`}
      label={label}
      checked={checked}
      onChange={handleChange}
    />
  );
};
