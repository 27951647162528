import { inSameYear } from 'helpers/datetime';
import { FormattedDate as IntlFormattedDate, IntlShape } from 'react-intl';

export interface IDateFormatting {
  year?: 'numeric' | '2-digit' | undefined;
  month?: 'short' | 'long' | 'numeric' | '2-digit';
  day?: 'numeric' | '2-digit';
  weekday?: 'long' | 'short' | 'narrow';
  hour?: 'numeric' | '2-digit' | undefined;
  minute?: 'numeric' | '2-digit' | undefined;
  second?: 'numeric' | '2-digit' | undefined;
}

export function removeYear(f: IDateFormatting) {
  f.year = undefined;
}

type FormattedDateProps = {
  value: Date | number;
  showYear?: boolean; // show year even if it is the current year
  format?: IDateFormatting;
  showTime?: boolean; // show the time as well
  nodate?: string;
};

export default function FormattedDate(props: FormattedDateProps) {
  const format1: IDateFormatting = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...props.format,
  };
  const d =
    typeof props.value === 'number' ? new Date(props.value) : props.value;
  if (!props.showYear && inSameYear(d, new Date())) removeYear(format1);
  const timeFormat: IDateFormatting = props.showTime
    ? {
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
      }
    : {};
  if (!props.value) return <>{props.nodate ?? '-'}</>;
  return (
    // <div className="date">
    <IntlFormattedDate value={props.value} {...timeFormat} {...format1} />
    // </div>
  );
}

export function formatDate(props: FormattedDateProps, intl: IntlShape): string {
  const format1: IDateFormatting = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    ...props.format,
  };
  const d =
    typeof props.value === 'number' ? new Date(props.value) : props.value;
  if (!props.showYear && inSameYear(d, new Date())) removeYear(format1);
  const timeFormat: IDateFormatting = props.showTime
    ? {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }
    : {};
  if (!props.value) return props.nodate ?? '-';
  return intl.formatDate(props.value, { ...timeFormat, ...format1 });
}

function pad2(n: number) {
  return n < 10 ? '0' + n : n;
}

export function dateForFileName(date: Date) {
  return (
    date.getFullYear().toString() +
    '.' +
    pad2(date.getMonth() + 1) +
    '.' +
    pad2(date.getDate()) +
    '-' +
    pad2(date.getHours()) +
    '.' +
    pad2(date.getMinutes()) +
    '.' +
    pad2(date.getSeconds())
  );
}
