import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { CreateProjectFormLink } from 'components/forms/CreateProjectForm';
import { useForms } from 'components/forms/Forms';
import { UserRegCodeFormLink } from 'components/user/UserRegCode';
import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IProject } from 'features/projects/projectsAPI';
import {
  ProjectsStateProps,
  asyncApiProjectsNew,
  asyncApiProjectsRemoved,
  asyncApiProjectsUpdated,
  fetchProjectsAsync,
  mapProjectsStateToProps,
} from 'features/projects/projectsSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { toLowerCase } from 'helpers/helpers';
import { sortBy } from 'helpers/sorting';
import ProjectCard from './ProjectCard';

import './ProjectOverview.scss';

function UnconnectedProjectOverview(
  props: UserStateProps &
    ProjectsStateProps &
    DialogueStateProps &
    AppSettingsStateProps,
) {
  const projects = sortBy(
    props.projects,
    'startedAt',
    'DESC',
    undefined,
    'title',
    'ASC',
    toLowerCase,
  );
  const { user, settings, dialogue } = props;
  const dispatch = useAppDispatch();
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const forms = useForms();

  const triggerReload = useCallback(() => {
    if (!forms.FormOpen) setReload((prev) => !prev);
  }, [forms.FormOpen]);

  useEffect(() => {
    // trigger a reload every 5 minutes, to update the unread counts
    const intervalId = setInterval(triggerReload, 5 * 60_000);
    return () => clearInterval(intervalId);
  }, [triggerReload]);

  useEffect(() => {
    if (user.id)
      dispatch(fetchProjectsAsync()).then(() => {
        setLoading(false);
      });
    // Below not needed, handled by DialogueView
    // if (user.id) {
    //   dispatch(persistCloseDialogues({ data: { o: [dialogue.id ?? 0] } }));
    // }
  }, [dispatch, user.id, dialogue.id, reload]);

  const asyncAPIQuery = 'Project?and=*,dialogues.*,subscribers.*,moderators.*';
  // const asyncAPIQuery = `Project?and=${constructURL({
  //   '*': true,
  // } as const satisfies Retrieving<'Project'>)}`;

  const [webSocket] = useState(AsyncAPI.connection);

  useEffect(() => {
    if (webSocket == null) return;

    console.log('Main useEffect called!');

    if (webSocket?.readyState !== 1) {
      console.log(
        'useEffect() main --> connection is not ready, readyState is not on 1',
      );
      return;
    }
    AsyncAPI.doMessage(asyncAPIQuery);

    const onMessageCallback = (payload: any, change: string) => {
      switch (change) {
        case 'new':
          dispatch(asyncApiProjectsNew({ data: payload }));
          break;
        case 'updated':
          console.log('dispatching projects update', payload);
          dispatch(asyncApiProjectsUpdated({ data: payload }));
          break;
        case 'removed':
          dispatch(asyncApiProjectsRemoved({ data: payload }));
          break;
      }
    };

    AsyncAPI.addOnMessageCallbackQueries(asyncAPIQuery, onMessageCallback);

    return () => {
      if (webSocket?.readyState !== 1) {
        console.log(
          'useEffect() return --> connection is not ready, readyState is not on 1',
        );
        return;
      }
      AsyncAPI.doMessageDisconnect(asyncAPIQuery);

      const index = AsyncAPI.onMessageCallbacksQueries.findIndex(
        ({ callback }) => callback === onMessageCallback,
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksQueries.splice(index, 1);
      }
    };
    // Check if webSocket is not null, before using the readyState for the dependency array
  }, [dispatch, webSocket != null ? webSocket?.readyState : null]);

  return (
    <div className="projects_container">
      <div className="my_projects">
        <FormattedMessage id={'PROJECTS.MINE'} />
      </div>
      <div className="projects cards_container">
        {loading ? (
          <div className="loading_projects">
            <FormattedMessage id={'PROJECTS.LOADING'} />
          </div>
        ) : projects?.length ? (
          projects.map((project: IProject, index: number) => (
            <ProjectCard
              project={project}
              key={index}
              triggerReload={triggerReload}
            />
          ))
        ) : (
          <div className="no_projects">
            <FormattedMessage id={'PROJECTS.NONE1'} />
            <br />
            <FormattedMessage id={'PROJECTS.NONE2'} />{' '}
            <UserRegCodeFormLink className="nav_item">
              <FormattedMessage id={'PROJECTS.ADD_REGCODE'} />
            </UserRegCodeFormLink>
            {settings.rights.userCreateProject ? (
              <>
                <br />
                <br />
                <CreateProjectFormLink className="nav_item">
                  <FormattedMessage id={'PROJECTS.NEW_PROJECT'} />
                </CreateProjectFormLink>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

const ProjectOverview = connect(mapProjectsStateToProps)(
  connect(mapUserStateToProps)(
    connect(mapDialogueStateToProps)(
      connect(mapAppSettingsStateToProps)(UnconnectedProjectOverview),
    ),
  ),
);
export default ProjectOverview;
