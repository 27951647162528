import { useCallback, useEffect, useRef, useState } from 'react';

import { useAppDispatch } from 'app/hooks';
import PopMenu from 'components/navigation/PopMenu';
import { IBlock } from 'features/block/blockAPI';
import { BlockType, patchBlockAsync } from 'features/block/blockSlice';
import { IChatBlock } from 'features/block/chatBlockAPI';
import { IDocumentBlock } from 'features/block/documentBlockAPI';
import { IListBlock } from 'features/block/listBlockAPI';
import { patchListBlockAsync } from 'features/block/listBlockSlice';
import { IPollBlock } from 'features/block/pollBlockAPI';
import { ISudokuBlock } from 'features/block/sudokuBlockAPI';
import { StateMan } from 'features/dialogue/dialogueSlice';

import './SettingSelect.scss';

type SettingSelectProps = {
  block: IBlock;
  prop: string;
  label: string;
  placeholder: string;
  options: Map<string, string>;
  notSub?: boolean; // true if the prop is not for a sub-block
  stateMan: StateMan;
};

export const SettingSelect = (props: SettingSelectProps) => {
  const dispatch = useAppDispatch();
  const { block, stateMan, prop, label, options, placeholder, notSub } = props;
  const [value, setValue] = useState<string | undefined>(undefined);
  const subBlock = useRef<
    | IChatBlock
    | IListBlock
    | IDocumentBlock
    | IPollBlock
    | ISudokuBlock
    | null
    | undefined
  >();

  useEffect(() => {
    subBlock.current =
      block.childChatBlock ||
      block.childListBlock ||
      block.childDocumentBlock ||
      block.childPollBlock ||
      block.childSudokuBlock;
    setValue(
      notSub
        ? block[prop as keyof typeof block]
        : subBlock.current
          ? (subBlock.current as any)[prop as keyof typeof subBlock]
          : undefined,
    );
  }, [block, prop]);

  const handleClick = useCallback(
    (event: React.MouseEvent, key: string) => {
      event.stopPropagation();
      event.preventDefault();
      if (notSub) {
        dispatch(
          patchBlockAsync({
            data: { id: block.id, [prop]: key },
            stateMan: stateMan,
          }),
        ).then((response: any) => {
          switch (response.payload.response?.status) {
            case 200:
            case 201:
              break;
            default:
              console.log('Internal error, setting not changed.');
          }
        });
      } else {
        switch (block.childType) {
          case BlockType.List:
            dispatch(
              patchListBlockAsync({
                id: subBlock.current?.id!,
                data: { [prop]: key },
                stateMan: stateMan,
              }),
            ).then((response: any) => {
              switch (response.payload.response?.status) {
                case 200:
                case 201:
                  break;
                default:
                  console.log('Internal error, setting not changed.');
              }
            });
            break;
        }
      }
    },
    [block, subBlock, dispatch, stateMan, prop],
  );

  return (
    <>
      <div className="select">
        <div className="select_label">{label}</div>
        <div className="select_value">
          {value ? options.get(value) : placeholder}
        </div>
        <PopMenu vertical shift={10} lift={35} shouldClose={true}>
          <PopMenu.Items>
            {[...options.entries()].map(
              ([key, optionValue]) =>
                key !== value && (
                  <div
                    key={key}
                    onClick={(e) => {
                      // setValue(key);
                      handleClick(e, key);
                    }}
                    // onClick={handleClick}
                  >
                    {optionValue}
                  </div>
                ),
            )}
          </PopMenu.Items>
        </PopMenu>
      </div>
    </>
  );
};
